@import "./Variables.scss";

#footer {
    margin-top: 19rem;
    height: 100%;
    background-color: rgba(#bebebf, .5);
    color: $secondary-green;
    font-weight: 600;
    .container-fluid {
        height: 100%;
        padding: 9rem 9rem 3rem 9rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        p {
            padding: .5rem 0;
            margin: 0;
        }
        .contact {
            padding: 0 11rem 0 2.5rem;
        }
    }
    .logo {
        img {
            height: 100%;
        }
    }
    h1 {
        font-size: 1.5rem;
        font-weight: 800;
        color: $primary-green;
        padding-bottom: 1.5rem;
        letter-spacing: 3px;
    }
    .nav-link {
        padding: .5rem 0;
        color: $secondary-green;
        font-weight: 600;
    }
    .nav-link:hover {
        color: $primary-gold;
    }
    .socmed {
        padding-top: 6rem;
        .row {
            .col-1 {
                margin-right: 1rem;
            }
            align-items: flex-end;
        }
        i {
            color: $secondary-green;
            font-size: 2.5rem;
            transition-timing-function: ease-in-out;
            transition: .1s;
        }
        i:hover {
            color: $primary-gold;
            transform: scale(.9);
        }
    }
    .footer {
        display: flex;
        align-items: flex-end;
        color: #666;
    }
    .scrollUp {
        a {
            display: inline-block;
            transition-timing-function: ease-in-out;
            transition: .2s;
        }
        a:hover {
            transform: translateY(-3rem);
        }
    }
}

#footer.active {
    margin-top: 0;
}