* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
}

html {
  scroll-behavior: smooth;
  font-size: 1rem;
}

@import './assets/sass/Variables.scss';
@import './assets/sass/Header.scss';
@import './assets/sass/Footer.scss';
@import './assets/sass/Home.scss';
@import './assets/sass/About.scss';
@import './assets/sass/Contact.scss';
@import './assets/sass/Responsive.scss';
