@import './Variables.scss';

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

#contactLanding {
    background-image: url(../images/contact/contactlanding.png);
    background-size: cover;
    background-position: center;
    height: 100%;
    // min-height: 200vh;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 15rem 8rem;
    .container-fluid {
        display: flex;
        justify-content: center;
        .headContainer {
            display: flex;
            justify-content: center;
            padding-bottom: 6rem;
        }
        .genContainer {
            height: auto;
            padding: 4rem;
            background-image: linear-gradient(to bottom right, rgba(#fff, .5), rgba($primary-gold, .5));
            border-radius: 20px;
            backdrop-filter: blur(.5rem);
            // box-shadow: 0px 8px 24px rgba($color: #000, $alpha: .15);
            box-shadow: 0px 8px 24px -18px;
        }
        .subhead {
            padding-bottom: 2rem;
            span {
                color: $secondary-green
            }
        }
        .phone, .email, .location {
            display: flex;
            align-items: center;
            padding-bottom: 2rem;
            .img-container {
                height: 100%;
                max-height: 3rem;
                min-width: 3rem;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 1.5rem;
                img {
                    height: 100%;
                    max-height: 3rem;
                    max-width: 3rem;
                }
            }
        }
        .formContainer {
            .form-row {
                margin-bottom: 0;
            }
            height: auto;
            input, textarea {
                height: 5rem;
                border-radius: 1rem;
                border: none;
                background-color: rgba($primary-gold, .9);
                color: #fff;
                padding: 0 2rem;
                font-size: 1.5rem;
                box-shadow: 0px 8px 24px -18px;
            }
            textarea {
                padding: 2rem;
                height: auto;
            }
            ::placeholder {
                color: $secondary-green;
            }
            button {
                width: 100%;
                background-color: $primary-green;
                border-radius: 1rem;
                padding: 1rem;
                color: #fff;
                font-size: 1.5rem;
                transition-timing-function: ease-in-out;
                transition: .1s;
            }
            button:hover {
                color: #fff;
                background-color: $primary-green;
                transform: scale(.98);
            }
        }
    }
}

#map {
    .mapContainer {
        display: flex;
        justify-content: center;
        padding-top: 10rem;
    }
    iframe {
        border: 0;
        border-radius: 20px;
        width: 100%;
        height: 50vh;
        box-shadow: 0px 8px 24px -18px;
    }
}