@import "./Variables.scss";

#aboutLanding {
    background-image: url(../images/about/aboutlanding.png);
    background-size: cover;
    background-position: center;
    height: 70vh;
    width: 100%;
    display: flex;
    align-items: center;
    .container-fluid {
        display: flex;
        justify-content: center;
    }
}

#aboutContent {
    padding: 9.5rem 0;
    .container {
        .row {
            align-items: center;
            .col-md-6 {
                padding: 1.5rem;
                p {
                    padding: 1.5rem 2rem 0 0;
                }
            }
        }
    }
}
.subhead {
    font-size: 2rem;
    font-weight: 800;
    color: $primary-green;
    padding-bottom: 20px;
}
.borderSub {
    border-left: 30px solid $primary-green;
    padding: .5rem 2rem !important;
    font-weight: 600;
    span {
        color: $primary-gold;
    }
}
.subPara {
    color: rgba($secondary-green, .5);
}

#missionVision {
    background-color: rgba($primary-green, .2);
    padding: 4rem;
    .row {
        align-items: center;
        .col-md-6 {
            img {
                padding-right: 2.5rem;
            }
            .subhead {
                span {
                    color: $secondary-green;
                }
            }
            .borderSub {
                padding: .5rem .5rem .5rem 2rem !important;
            }
            p {
                padding: 1.5rem .5rem .5rem 0;
            }
        }
    }
}

#stats {
    padding: 18rem 14rem;
    .container-fluid {
        display: flex;
        align-items: center;
        justify-content: center;
        .col-md-4 {
            border-left: 5px solid $primary-green;
            padding: 0 4rem;
            display: flex;
            align-items: center;
            justify-content: center;
            h1 {
                font-size: 5rem;
                font-weight: 800;
                padding-right: 1.5rem;
                color: $primary-gold;
            }
            h2 {
                font-size: 2rem;
                font-weight: 600;
            }
        }
        .lastStat {
            border-right: 5px solid $primary-green;
        }
    }
}

#coreValues {
    .container-fluid {
        padding: 5rem 9.5rem 5rem 12.5rem;
        background-color: rgba($primary-gold, .5);
        .row {
            // align-items: center;
            h1 {
                display: inline-block;
                font-weight: 600;
                color: $primary-green;
                padding-bottom: 1.5rem;
                border-bottom: 5px solid $primary-green;
                margin-bottom: 2.5rem; 
            }
            p {
                font-size: 1.5rem;
                color: $secondary-green;
                padding-right: 2rem;
            }
        }
    }
}

#joinUs {
    padding: 10rem;
    padding-bottom: 0;
    .container-fluid {
        img {
            padding: 1rem 0;
            filter: drop-shadow(0px 8px 5px rgba(#333, .2));
            // box-shadow: 0px 8px 24px -18px;
        }
        .col-xl-5 {
            padding: 1.5rem;
            .boldPara {
                font-weight: 600;
                font-size: 2rem;
                span {
                    color: $primary-gold;
                }
            }
            .generalPara {
                padding: 1rem 0;
            }
            .joinNow {
                .navJoin {
                    display: flex;
                    align-items: center;
                    padding-top: 1.5rem;
                    color: $primary-green;
                    text-decoration: none;
                    transition-timing-function: ease-in-out;
                    transition: .1s;
                    i {
                        padding-right: 1.5rem;
                    }
                    p {
                        font-size: 2.5rem;
                        font-weight: 600;
                        margin: 0;
                    }
                }
                .navJoin:hover {
                    color: $primary-gold;
                }
            }
        }
    }
}