@import './Variables.scss';

#landing {
    background-image: url(../images/home/landing.png);
    background-size: cover;
    background-position: center;
    height: 114vh;
    width: 100%;
    display: flex;
    align-items: center;
    .container-fluid {
        padding: 5rem 11rem;
    }
    h2 {
        font-size: 2rem;
        color: $tertiary-green;
        padding-top: 1rem;
    }
    .btn {
        font-size: 1rem;
        font-weight: 600;
        padding: 1rem 2rem;
        border-radius: .5rem;
        color: #fff;
        background-color: $primary-green;
        margin-top: 2.5rem;
        transition-timing-function: ease-in-out;
        transition: .1s;
    }
    .btn:hover {
        color: #fff;
        background-color: $primary-gold;
        transform: scale(.98);
    }
}
.header {
    font-size: 5.5rem;
    color: #fff;
    span {
        font-weight: 800;
    }
    .gold {
        color: $primary-gold;
    }
}

#ourClient {
    padding: 7.5rem 0 4.5rem 0;
    .container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .companyLogo {
            display: flex;
            align-items: center;
            padding-top: 3.5rem;
            img {
                padding: .5rem;
                transition-timing-function: ease-in-out;
                transition: .1s;
            }
            img:hover {
                transform: scale(1.1);
            }
        }
    }
}
.titleHead {
    padding-bottom: 1.5rem;
    border-bottom: 5px solid $primary-green;
    color: $secondary-green;
    span {
        color: $primary-gold;
        font-weight: 600;
    }
}

#farmers {
    background-image: url('../images/home/farmersBG.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 70vh;
    display: flex;
    align-items: center;
    .row .col-12 {
        padding: 7rem 6.5rem 6.5rem;
        display: flex;
        align-items: center;
        background: url('../images/home/greenBG.png');
        background-size: contain;
        background-repeat: no-repeat;
        background-position-x: -9rem;
        background-position-y: center;
        p {
            padding: 4.5rem 6rem 4.5rem 0;
            font-size: 2.5rem;
            color: $secondary-green;
        }
        .row {
            .col-xl-7 {
                display: flex;
                align-items: center;
                justify-content: center;
                img {
                    filter: drop-shadow(0px 8px 5px rgba(#333, .2));
                }
            }
            .col-xl-5 {
                display: flex;
                align-items: center;
                // justify-content: center;
            }
        }
    }
}

#weAreDifferent {
    padding: 7.5rem 0 4.5rem 0;
    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        .row {
            display: flex;
            align-items: center;
            padding-top: 3rem;
            .col-md-6 {
                padding-top: 6rem;
                .subHead {
                    font-size: 2rem;
                    font-weight: 800;
                    color: $secondary-green;
                    span {
                        color: $primary-gold;
                    }
                }
                .content {
                    padding: 2.5rem 0;
                    p {
                        border-left: 30px solid $primary-green;
                        padding: .5rem 1.5rem .5rem 2rem;
                    }
                }
                img {
                    height: auto;
                    max-height: 31.5rem;
                    padding: 1.5rem;
                }
            }
            .network, .innovation {
                img {
                    padding-right: 3rem;
                }
            }
        }
    }
}
.generalPara {
    font-size: 1.5rem;
    color: $secondary-green;
    margin: 0;
}

#video {
    .container-fluid {
        margin-top: 6rem;
        padding: 0;
        // background-color: $primary-gold;
        video {
            object-fit: cover;
            object-position: center;
            height: 70vh;
        }
    }
}

#weStand {
    margin-top: 9.5rem;
    .container-fluid .row {
        padding: 0 13.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        .weStandImage {
            padding: 3rem;
            height: 100%;
            width: 100%;
            max-height: 50rem;
        }
        .weStand {
            padding-right: 9.5rem;
            h1 {
                border-bottom: none;
                font-weight: 800;
                padding-bottom: 3rem;
                span {
                    font-weight: 800;
                    color: $primary-green;
                }
            }
            .weStandIcon {
                height: 100%;
                max-height: 10rem;
                padding-bottom: 3rem;
            }
        }
    }
}

#feature {
    padding-top: 9.5rem;
    .row {
        padding: 3rem;
        align-items: flex-end;
        justify-content: center;
    } 
    figure {
        text-align: center;
        img {
            height: auto;
            // max-height: 15rem;
            padding-bottom: 4rem;
            transition-timing-function: ease-in-out;
            transition: .1s;
        }
        figcaption {
            font-size: 1.5rem;
            font-weight: 600;
            color: $primary-gold;
        }
        img:hover {
            transform: scale(1.1);
        }
    }
}