@import './Variables.scss';

.navbar {
    padding: 2.5rem 11rem;
    transition-timing-function: ease-in-out;
    transition: .4s;
    .container-fluid {
        .navbar-brand img {
            height: 100%;
        }
        .navbar-nav {
            .nav-link {
                font-size: 1rem;
                color: #fff;
                margin: 0 2rem;
                border-bottom: 2px solid transparent;
            }
            .nav-link:hover {
                color: $primary-gold;
                border-bottom: 2px solid $primary-gold;
                transition-timing-function: ease-in-out;
                transition: .2s;
            }
            .supplier {
                color: $primary-green;
            }
            .selected {
                color: $primary-gold;
                border-bottom: 2px solid $primary-gold;
                transition-timing-function: ease-in-out;
                transition: .2s;
            }
        } 
    }
}
.navbar.active {
    background-color: rgba($primary-grey, .3);
    transition-timing-function: ease-in-out;
    transition: .4s;
    padding: .5rem 2rem;
    .container-fluid {
        .navbar-brand img {
            height: 100%;
            max-height: 3rem;
        }
    }
}