@import './Variables.scss';

// 1650 x 1050 Desktop
@media only screen and (max-width: 1680px) {
    html {
        font-size: 14px;
    }
    .generalPara {
        font-size: 24px;
    }
    #footer {
        p, .nav-link {
            font-size: 16px;
        }
    }
}
// 1366 x 768 Desktop
@media only screen and (max-width: 1366px) {
    html {
        font-size: 12px;
    }
    #weAreDifferent, #aboutContent, #missionVision {
        .container .row {
            padding-left: 5rem;
        }
    }
    .generalPara {
        font-size: 22px;
    }
    #farmers {
        p {
            font-size: 22px !important;
        }
    }
    #footer {
        p, .nav-link {
            font-size: 14px;
        }
    }
    #coreValues {
        p {
            font-size: 16px !important;
        }
    }
    #contactLanding {
        .generalPara {
            font-size: 20px;
        }
    }
}

// 1024 x 1366 Tablet Ipad Pro
@media only screen and (max-width: 1024px) {
    #header {
        .navbar {
            padding: 3rem;
        }
        .navbar.active {
            .navbar-brand img {
                max-height: none;
            }
        }
    }
    #footer {
        .container-fluid {
            .socmed {
                padding-bottom: 8rem;
            }
            .logo img {
                height: auto;
                max-height: none;
            }
            .contact, .products {
                padding: 0;
            }
            .footer {
                padding-top: 5rem;
            }
        }
    }
    #farmers {
        .row .col-12 {
            padding: 5rem;
            background-image: none;
            background-color: rgba($tertiary-green, .5);
            p {
                padding: 0 5rem 5rem;
                text-align: center;
                font-size: 3rem !important;
            }
        }
    }
    #weAreDifferent, #aboutContent, #missionVision {
        .container .row {
            padding-left: 4rem;
        }
    }
    #weStand {
        .container-fluid .row {
            padding: 0 5rem;
            .weStand {
                padding-right: 5rem;
            }
        }
    }
    #stats {
        padding: 15rem 5rem;
        .container-fluid {
            .col-md-4 {
                h1 {
                    font-size: 4.5rem;
                }
                h2 {
                    font-size: 1.5rem;
                }
            }
        }
    }
    #coreValues {
        .container-fluid {
            padding: 5rem 3rem 5rem 5rem;
            .row {
                p {
                    font-size: 14px !important;
                }
            }
        }
    }
    #joinUs {
        .container-fluid {
            .col-xl-5 {
                padding-top: 5rem;
            }
        }
    }
    #contactLanding {
        .container-fluid {
            .generalPara {
                font-size: 2rem;
            }
            .genContainer {
                padding: 4rem 4rem 3rem;
                // backdrop-filter: blur(0);
            }
            .formContainer {
                padding: 5rem 0;
            }
        }
    }
}

// 768 x 1024 Tablet Ipad
@media only screen and (max-width: 768px) {
    #header {
        .navbar {
            padding: 2rem 1rem;
            .navbar-brand img {
                max-height: 3.5rem;
                padding-left: 2rem;
            }
        }
        .navbar.active {
            .navbar-brand img {
                max-height: 3.5rem;
            }
        }
    }
    #footer {
        .container-fluid {
            .contact, .products {
                padding: 0;
            }
        }
    }
    .generalPara {
        font-size: 20px;
    }
    #landing {
        .container-fluid {
            padding: 8rem;
        }
    }
    #farmers {
        .row .col-12 {
            p {
                padding: 0 5rem;
            }
        }
    }
    #weAreDifferent {
        .container .row {
            padding-left: 2rem;
        }
    }
    #weStand {
        .container-fluid .row {
            padding: 0;
            .weStand {
                padding: 0 4rem 0 0;
            }
        }
    }
    #feature {
        figure {
            margin-top: 4rem;
        }
    }
    #aboutContent, #missionVision {
        .container .row {
            padding-left: 2rem;
        }
    }
    #stats {
        .container-fluid {
            .col-md-4 {
                padding: 0 2.5rem;
                h1 {
                    font-size: 4rem;
                }
                h2 {
                    font-size: 1rem;
                }
            }
        }
    }
    #contactLanding {
        padding: 15rem 5rem;
        .container-fluid {
            .genContainer {
                padding: 3rem 3rem 1rem;
                .generalPara {
                    font-size: 20px;
                }
            }
        }
    }

}

// 414 x 736 Mobile Iphone 6/7/9 plus / Pixel 2/2XL
@media only screen and (max-width: 414px) {

    html {
        font-size: 10px;
    }

    #header {
        .navbar {
            padding: 3rem;
            .navbar-brand img {
                max-height: 3rem;
                padding: 0;
            }
            .navbar-toggler {
                padding: 0;
            }
        }
        .navbar.active {
            padding: 1rem 2rem;
            .navbar-brand img {
                max-height: 3rem;
            }
        }
        .navbar-nav .nav-link {
            margin: 1rem 0 0 auto;
        }
    }
    #footer {
        .container-fluid {
            padding: 10rem 2rem 0;
            .row {
                .logo {
                    display: block;
                    text-align: center;
                }
            }
            .socmed {
                padding-bottom: 5rem;
            }
            .contact, .products, .links {
                padding: 2rem;
            }
            .footer {
                div {
                    padding: 1rem 2rem;
                }
            }
        }
    }
    .header {
        font-size: 4rem;
    }
    .generalPara {
        font-size: 16px;
    }
    #landing {
        .container-fluid {
            padding: 0 2rem;
            h2 {
                font-size: 1.8rem;
            }
        }
    }
    #ourClient {
        padding-bottom: 0;
        .container {
            padding: 0 2rem;
        }
    }
    #farmers {
        .row .col-12 {
            padding: 3rem;
            p {
                padding: 0 2rem;
                font-size: 1.8rem !important;
            }
        }
    }
    #weAreDifferent {
        .container .row {
            padding-left: 5rem 2rem 0;
            .animalCare {
                img {
                    padding-right: 3rem;
                }
            }
        }
    }
    #weStand {
        margin-top: 8rem;
        .container-fluid .row {
            .weStand {
                text-align: center;
                padding: 5rem 2rem;
            }
        }
    }
    #feature {
        padding-top: 0;
        figure {
            margin-top: 4rem;
        }
    }
    #aboutContent {
        padding: 10rem 2rem;
        .container .row {
            padding: 0;
            justify-content: center;
            .col-md-6 {
                padding: 0;
                img {
                    margin-top: 5rem;
                    height: auto;
                    max-height: 25rem;
                }
            }
        }    
    }
    #missionVision {
        padding: 4rem 2rem;
        .container .row {
            padding: 0;
            .col-md-6 {
                padding: 0;
                img {
                    margin-top: 5rem;
                    padding: 0;
                    height: auto;
                }
                .subhead {
                    padding-top: 3rem;
                }
            }
        }
    }
    #stats {
        padding: 5rem 2rem 7rem;
        .container-fluid {
            .col-md-4 {
                text-align: center;
                display: inline-block;
                border: none;
                padding: 2rem 0;
                h1 {
                    padding: 0;
                    font-size: 5rem;
                }
                h2 {
                    font-size: 1.6rem;
                }
            }
        }
    }
    #coreValues {
        .container-fluid {
            padding: 5rem 2rem;
            .row {
                p {
                    font-size: 1.6rem !important;
                }
                .quality, .flexibility {
                    padding-top: 3rem;
                }
            }
        }
    }
    #joinUs {
        padding: 10rem 2rem 0;
        .container-fluid {
            .col-4 {
                padding: 0 1rem;
            }
            .col-xl-5 {
                padding: 5rem 1rem;
            }
        }
    }
    #contactLanding {
        padding: 15rem 2rem;
        .container-fluid {
            .genContainer {
                padding: 2.5rem 2.5rem 1.5rem;
                .generalPara {
                    font-size: 1.6rem;
                }
            }
        }
        .map {
            padding: 0;
            .mapContainer {
                padding-top: 5rem;
            }
            iframe {
                height: 80vh;
            }
        }
    }
}

// 375 x 812 Mobile Iphone 6/7/8/X
@media only screen and (max-width: 375px) {
    #contactLanding {
        .container-fluid {
            .genContainer {
                padding: 2rem 2rem 1rem;
            }
        }
    }
}
